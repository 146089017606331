import React, { useState } from "react";

import { cache } from "/apollo/client/local";
import { List, Loading } from "/component/base";
import UpdateUserPasswordModal from "/component/modal/UpdateUserPasswordModal";
import { useAuth, useTranslation } from "/hook";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";
import { parsePhone } from "/util/formatText";

import SettingsItem from "../SettingsItem";
import { SettingsItem as SettingsItemType } from "../SettingsItem/SettingsItem.types";
import { useAccountQuery } from "../useSettings";
import { ListContainer } from "./SettingsList.styles";
import { useUpdateLinkedPhoneNumber } from "./useUpdateLinkedPhoneNumber";

const SettingsList = () => {
  const { t } = useTranslation("settingsList");
  const { changeEmail, changePhoneNumber } = useAuth(false);
  const [updateLinkedPhone] = useUpdateLinkedPhoneNumber();
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  const { data, loading } = useAccountQuery();
  const linkedPhoneNumber = data?.viewer?.user?.account.phoneNumber;
  const email = data?.viewer?.user?.account.email;
  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_ACCOUNT,
      source: AnalyticsSource.ACCOUNT_SETTINGS,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  const phoneNumber = linkedPhoneNumber
    ? (parsePhone(linkedPhoneNumber.replace("+", "")) as string)
    : "";
  const handleEmailClick = async () => {
    logButtonClickEvent("Edit email");
    const newEmail = await changeEmail();

    if (newEmail) {
      cache.modify({
        id: "UserAccount:{}",
        fields: {
          email() {
            return newEmail;
          },
        },
      });
    }
  };

  const handlePhoneNumberClick = async () => {
    logButtonClickEvent("Edit phone number");
    const newPhoneNumber = await changePhoneNumber();
    if (newPhoneNumber) {
      cache.modify({
        id: "UserAccount:{}",
        fields: {
          phoneNumber() {
            return newPhoneNumber;
          },
        },
      });

      await updateLinkedPhone({
        variables: {
          data: {
            linkedPhoneNumber: newPhoneNumber,
          },
        },
      });
    }
  };

  const handleChangePasswordClick = async () => {
    logButtonClickEvent("Edit password");
    setShowPasswordReset(true);
  };

  const details: SettingsItemType[] = [
    { onClick: handleEmailClick, label: t("settings.email"), value: email || "" },
    { onClick: handlePhoneNumberClick, label: t("settings.phoneNumber"), value: phoneNumber },
    { onClick: handleChangePasswordClick, label: t("settings.changePassword"), value: "" },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <UpdateUserPasswordModal
        isOpen={showPasswordReset}
        close={() => {
          setShowPasswordReset(false);
        }}
      />
      <ListContainer css={[layout.flexVertical, layout.paddingVertical("standard")]}>
        <List
          as="Buttons"
          data={details}
          chevronVariant="grey"
          hideLastChildBorder
          showChevron
          onClick={({ onClick }) => onClick && onClick()}
          renderItem={({ label, value }) => (
            <SettingsItem listItemLabel={label} listItemValue={value} />
          )}
        />
      </ListContainer>
    </>
  );
};

export default SettingsList;
