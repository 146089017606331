import React, { Fragment, useEffect } from "react";
import { useLocation } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";

import DemographicsLayout from "/component/layout/DemographicsLayout";
import AccountPage from "/component/page/Account";
import AppointmentsPage from "/component/page/Appointments";
import DemographicsPage from "/component/page/Demographics";
import AccountErrorPage from "/component/page/Error/AccountError";
import FaqPage from "/component/page/Faq";
import HomePage from "/component/page/Home";
import MyHealthPage from "/component/page/MyHealth";
import NotFoundPage from "/component/page/NotFound";
import SchedulingPage from "/component/page/Scheduling";
import SignUp from "/component/page/SignUp";
import TelehealthRoutes from "/component/page/Telehealth";
import UrgentCareRoutes from "/component/page/UrgentCare";
import { SignUpFormType } from "/constant/signUp.constant";
import routes from "/constant/url.constant";
import { logScreenViewEvent } from "/util/firebase.util";

// Routes Helper
import AppRoute from "./AppRoute";

const SIGN_UP_V2_ENABLED = process.env.SIGN_UP_V2_ENABLED === "true";

// Redirect all requests with one or more trailing slashes to the path
// with slashes removed. This enforces a canonical URL for each view
// and avoids avoids issues with path concatenation introducing
// redundant slashes.
const trailingSlashRedirect = (
  <Route
    exact
    strict
    path="/:url/"
    render={(props) => <Redirect to={props.location.pathname.replace(/\/+$/, "")} />}
  />
);

// Define the Routes of the application
const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    logScreenViewEvent(location.pathname, location.search);
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);
  return (
    <Switch>
      {trailingSlashRedirect}

      {/* Dynamic link redirects */}
      <Redirect from="/secure-messaging/inbox" to={routes.messaging} />
      <Redirect from="/test-results/:id" to={routes.result} />
      <Redirect from="/prescription/:prescriptionId" to={routes.prescription} />

      <Route
        exact
        path="/emailVerification/:code"
        render={(props) => (
          <Redirect
            to={`${routes.demographics}?form=${SignUpFormType.EmailVerification}&code=${props.match.params.code}`}
          />
        )}
      />

      {/* App routes */}
      {SIGN_UP_V2_ENABLED ? (
        <Route exact path={routes.demographics} component={SignUp} />
      ) : (
        <AppRoute
          layout={DemographicsLayout}
          exact
          path={routes.demographics}
          component={DemographicsPage}
        />
      )}
      <AppRoute exact path={routes.root} component={HomePage} />
      <AppRoute exact path={routes.passwordReset} component={HomePage} />
      <AppRoute path={routes.myHealth} component={MyHealthPage} />
      <AppRoute path={routes.account} component={AccountPage} />
      <AppRoute path={routes.appointments} component={AppointmentsPage} />
      <AppRoute path={routes.scheduling} component={SchedulingPage} />
      <Route path={routes.telehealth} component={TelehealthRoutes} />
      <AppRoute path={routes.urgentCare} component={UrgentCareRoutes} />
      <AppRoute path={routes.faq} component={FaqPage} />
      <AppRoute
        path={routes.accountConnectionError}
        component={AccountErrorPage}
        layout={Fragment}
      />
      <Route exact path="/" render={() => <Redirect to={routes.root} />} />
      <AppRoute component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
