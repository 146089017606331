import React from "react";
import { Trans } from "react-i18next";

import { useGenerateOnlineCheckInUrl } from "/b2c/mutation/useGenerateOnlineCheckInUrl.b2c";
import { AppointmentStatus, VisitType } from "/b2c/schema/types";
import { Button, Divider, InlineMessage, ListItem, Text } from "/component/base";
import { AlertCircle, Clipboard, CornerUpRight, Video } from "/component/base/Icon";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { date, formatText, links } from "/util";
import { showCompleteIntake } from "/util/appointment.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  IndentifierParams,
  logEvent,
} from "/util/firebase.util";

import LocationItem from "../LocationItem";
import ProviderItem from "../ProviderItem";
import { CancelButton, Label } from "./AppointmentDetails.styles";
import { Props } from "./AppointmentDetails.types";

const AppointmentDetails = ({ appointment, appointmentTelehealthInfo, onCancelClick }: Props) => {
  const { t } = useTranslation("partial-appointmentDetails");
  const [getPrevisitSessionUrl, { loading: epionPrevisitLoading }] = useGenerateOnlineCheckInUrl({
    variables: {
      appointmentId: appointment.appointmentId,
      departmentId: appointment.departmentId,
    },
  });

  // ==================================================================
  // Set up and compute variables
  // ==================================================================
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
  };

  const endDate = new Date(appointment.endDateTime);
  const startDate = new Date(appointment.startDateTime);

  // Format data to render
  const endTime = endDate.toLocaleTimeString("default", timeOptions);
  const startTime = startDate.toLocaleTimeString("default", timeOptions);
  const { hours, minutes } = date.getDuration(startDate, endDate);
  const renderedDate = date.formatDate(startDate, "EEEE, LLLL d, y");

  const address: formatText.Address = {
    line1: appointment.department?.address,
    line2: appointment.department?.address2,
    city: appointment.department?.city,
    state: appointment.department?.state,
    postalCode: appointment.department?.zipCode,
  };
  const addressAriaLabel = `${appointment.department?.name}. ${formatText.formatAddress(address)}`;

  // Appointment state variables
  const now = new Date();
  const isPastVisit = startDate.getTime() < now.getTime();
  const isVirtual = appointment?.visitType === VisitType.VIRTUAL;

  const showCancellationButton =
    !isPastVisit && AppointmentStatus.FUTURE === appointment.appointmentStatus;

  const isNoShow =
    endDate.getTime() < now.getTime() &&
    [AppointmentStatus.FUTURE, AppointmentStatus.OPEN].includes(appointment.appointmentStatus);
  const isCancelled = AppointmentStatus.CANCELLED === appointment.appointmentStatus;
  const canStartVirtualVisit =
    date.isDateToday(startDate) && !isPastVisit && !isCancelled && !!appointmentTelehealthInfo;

  // ==================================================================
  // Event handlers
  // ==================================================================

  const handleLocationClick = () => {
    logButtonClickEvent("Facility", {
      facility_id: appointment.departmentId.toString(),
    });
  };
  const handlePressCompleteIntake = () => {
    logButtonClickEvent("Complete intake form");

    getPrevisitSessionUrl();
  };
  const logButtonClickEvent = (buttonName: string, params?: IndentifierParams) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.APPOINTMENT_DETAILS,
      source: AnalyticsSource.APPOINTMENT_DETAILS,
      button_name: buttonName,
      appointment_id: appointment.appointmentId.toString(),
      ...params,
    } as ButtonClickParams);
  };

  // Text requires children, which it will receive from react-i18n, but we don't want to set any here
  // eslint-disable-next-line
  // @ts-ignore
  const BoldTranslationText = <Text variant="body1Bold" />;

  // ==================================================================
  // Figure out if we need to render any alerts at the top of the view
  // ==================================================================
  const alerts = [];
  if (isVirtual && canStartVirtualVisit) {
    alerts.push(
      <InlineMessage icon={<Video />}>
        <Text>
          <Trans components={{ bold: BoldTranslationText }} i18nKey="visitReady" t={t} />
        </Text>
      </InlineMessage>,
    );
  }

  if (isVirtual && !canStartVirtualVisit && !isCancelled && !isPastVisit) {
    alerts.push(
      <InlineMessage icon={<Video />}>
        <Text>
          <Trans components={{ bold: BoldTranslationText }} i18nKey="linkToBeProvided" t={t} />
        </Text>
      </InlineMessage>,
    );
  }

  if (isCancelled) {
    alerts.push(
      <InlineMessage icon={<AlertCircle />} variant="important">
        <Text variant="body2Bold">{t("visitCanceled")}</Text>
      </InlineMessage>,
    );
  }

  if (isNoShow) {
    alerts.push(
      <InlineMessage icon={<AlertCircle />} variant="important">
        <Text variant="body2Bold">{t("visitNoShow")}</Text>
      </InlineMessage>,
    );
  }

  return (
    <article>
      {!!alerts.length && (
        <ul
          css={[layout.spacedChildrenVertical(), layout.margin("skip", "skip", "expanded")]}
          aria-label={t("ariaLabel.appointmentAlerts")}
        >
          {alerts.map((alertChild, i) => (
            <li key={i}>{alertChild}</li>
          ))}
        </ul>
      )}
      <Label>{t("dateTime")}</Label>
      <Text
        color="textTitle"
        variant="body1Bold"
        element="div"
        css={layout.margin("skip", "skip", "base")}
      >
        {renderedDate}
      </Text>
      <Text color="textPrimary">
        {`${startTime} – ${endTime} (${hours ? `${hours} hrs, ` : ""}${minutes} mins)`}
      </Text>
      {appointment.provider && (
        <>
          <Divider css={layout.margin("standard", "skip")} />
          <Label>{t("provider")}</Label>
          <ListItem.Div showBorder={false} showChevron={false}>
            <ProviderItem
              css={layout.flexItemAuto}
              photoUrl={appointment.provider.imageUrl}
              name={{
                firstName: appointment.provider.firstName,
                lastName: appointment.provider.lastName,
              }}
              title={appointment.provider.displayName}
              subtitle={appointment.provider.specialty}
            />
          </ListItem.Div>
        </>
      )}
      {!isVirtual && appointment.department && (
        <>
          <Divider css={layout.margin("standard", "skip")} />
          <Label>{t("location")}</Label>
          <a
            css={[layout.padding("skip", "skip", "standard"), layout.flexCenterHorizontal]}
            href={links.getGoogleMapsLink({ address })}
            target="_blank"
            rel="noreferrer"
            aria-label={addressAriaLabel}
            onClick={handleLocationClick}
          >
            <LocationItem
              css={layout.flexItemAuto}
              title={appointment.department?.name || t("noLocation")}
              address={address}
            />
            <CornerUpRight
              color="textTitle"
              size={24}
              background={{ color: "brandPrimaryLight" }}
            />
          </a>
        </>
      )}
      {/* visitReason has been replaced by patientAppointmentTypeName as visitReason field do not exists in b2c-graphql */}
      {!!appointment.patientAppointmentTypeName && (
        <>
          <Divider css={layout.margin("standard", "skip")} />
          <Label>{t("visitReason")}</Label>
          <Text>{appointment.patientAppointmentTypeName}</Text>
        </>
      )}

      <div css={[{ marginTop: 48, width: "83%" }]}>
        {!!showCompleteIntake(appointment) && (
          <Button
            css={layout.flexCenter}
            variant="tertiary"
            fullWidth="percent"
            isLoading={epionPrevisitLoading}
            onClick={handlePressCompleteIntake}
          >
            <Clipboard size={16} />
            <span css={layout.margin("skip", "skip", "skip", "condensed")}>
              {t("completeIntake")}
            </span>
          </Button>
        )}

        {isVirtual && canStartVirtualVisit && (
          <Button
            css={[layout.margin("expanded", "skip", "skip", "skip"), layout.flexCenter]}
            fullWidth="percent"
            isLoading={false}
            onClick={() => {
              logButtonClickEvent("Start visit");
              logEvent(AnalyticsEvent.ATHENA_VISIT_START, {
                is_caregiver: "false",
              });
              window.open(appointmentTelehealthInfo.deepLink, "_blank");
            }}
          >
            <span css={layout.margin("skip", "skip", "skip", "condensed")}>{t("startVisit")}</span>
          </Button>
        )}
      </div>

      {showCancellationButton && (
        <CancelButton
          variant="borderBottomSecondary"
          onClick={() => {
            logButtonClickEvent("Cancel this appointment", {
              appointment_id: appointment.appointmentId.toString(),
            });
            onCancelClick();
          }}
        >
          {t("cancelAppointment")}
        </CancelButton>
      )}
    </article>
  );
};

export default AppointmentDetails;
