import { useMutation, useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { auth, scheduling } from "/apollo/client/local";
import bookAppointmentGql from "/apollo/mutation/bookAppointment";
import createReservationGql from "/apollo/mutation/createReservation";
import { ProviderAvailabilitiesListFragment_availabilities_pages as Availability } from "/apollo/schema";
import { useAppointmentsIndex } from "/b2c/query/appointments.b2c";
import { useToastContext } from "/component/provider/ToastProvider";
import routes from "/constant/url.constant";
import { useAuth, useTranslation } from "/hook";

type BeginBookingProps = {
  availabilityId: string;
  availability: Availability;
};

const useAppointmentBooking = () => {
  const history = useHistory();
  const { showToast } = useToastContext();
  const { reasonForVisit, booking } = useReactiveVar(scheduling.var);
  const { t } = useTranslation("availabilities-all");
  const { isAuthenticated } = useReactiveVar(auth.var);
  const { login } = useAuth();
  const [createReservation] = useMutation(createReservationGql);

  const beginBooking = async ({ availabilityId, availability }: BeginBookingProps) => {
    try {
      const timeSlot = availability.timeSlots.find((timeSlot) => {
        return timeSlot.availabilityId === availabilityId;
      });

      if (!timeSlot) {
        throw new Error(
          "The availabilityId passed to beginBooking does not match any of the time slots associated with the avaiability passed in",
        );
      }

      const provider = availability.providerDetails;
      const { data } = await createReservation({
        variables: {
          availabilityId,
        },
        context: {
          provider: {
            providerId: provider ? provider.id : "",
            providerAthenaId: provider ? provider.athenaId : "",
          },
        },
      });

      // handle in catch?
      if (data && data.createReservation.errors.length > 0) {
        throw new Error(data.createReservation.errors[0].message || "");
      }

      scheduling.updateBooking({
        reservation: data.createReservation.reservation,
        availability,
        timeSlot,
      });

      // Note: We should never go to the extra VisitReason page now but leaving here just in case
      const nextPage = !reasonForVisit
        ? `${routes.scheduling}${routes.visitReason}`
        : `${routes.scheduling}${routes.confirm}`;

      if (!isAuthenticated) {
        // This will redirect.
        await login(`${window.location.origin}${nextPage}`);
        return;
      }

      // Using this setTimeout to prevent immediately changing pages, which causes the components to
      // unmount and generates "cannot call `setState` of unmount" as they cleanup. Using
      // `setTimeout(fn, 0)` will delay the `history.push()` happening until the next render cycle
      setTimeout(() => {
        history.push(nextPage);
      }, 0);
    } catch (e: any) {
      showToast({
        icon: "alert",
        message: t("couldNotBook", "Could not book this time slot. Try again."),
        type: "error",
      });
      console.error(e);
    }
  };

  const { refetch: refetchAppointments } = useAppointmentsIndex(
    { cacheRefresh: true },
    { skip: true },
  );

  const [bookAppointmentMutation] = useMutation(bookAppointmentGql);

  const bookAppointment = async () => {
    try {
      if (booking?.reservation && booking?.timeSlot) {
        const { data } = await bookAppointmentMutation({
          variables: {
            data: {
              reasonForAppointment: reasonForVisit,
              reservationId: booking.reservation.id,
              timeSlot: {
                calendarId: booking.timeSlot.calendarId,
                date: booking.timeSlot.date,
                startTime: booking.timeSlot.startTime,
                endTime: booking.timeSlot.endTime,
                appointmentTypeId: booking.timeSlot.appointmentTypeId,
              },
            },
          },
        });

        if (data && data.bookAppointment.errors.length > 0) {
          throw new Error(data.bookAppointment.errors[0].message || "");
        }

        // Refetch appointments to update the cache
        await refetchAppointments({ cacheRefresh: true });

        scheduling.update({ isBookingComplete: true });
        history.push(`${routes.scheduling}${routes.confirmed}`);
      }
    } catch (e: any) {
      showToast({ icon: "alert", message: t("couldNotBook"), type: "error" });
      console.error(e);
    }
  };

  return {
    beginBooking,
    bookAppointment,
  };
};

export default useAppointmentBooking;
