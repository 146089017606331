import { Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";

import { StateCode } from "/apollo/schema/types";
import useLazyDemographics from "/b2c/query/useLazyDemographics.b2c";
import Loading from "/component/base/Loading";
import AddressForm, { formAddressSchema } from "/component/form/AddressForm";
import { Title } from "/component/form/Telehealth/Telehealth.styles";
import FormContent from "/component/partial/FormContent";
import {
  PharmacyAddressConfirmationProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { TelehealthFormProps } from "../Telehealth.types";

const US_COUNTRY_CODE = "US";

const PharmacyAddressConfirmation = ({
  initialValues,
  handleError,
  handleSubmit,
}: TelehealthFormProps<PharmacyAddressConfirmationProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");
  const { t: addressformTranslations } = useTranslation("form-Address");
  const addressValidationSchema = formAddressSchema(addressformTranslations);

  const { sdk, consumer } = useAmwellPrevisitContext();
  const [getPatientData] = useLazyDemographics();
  const { showToast } = useToastContext();

  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState<PharmacyAddressConfirmationProps>(initialValues);

  useEffect(() => {
    const initForm = async () => {
      setLoading(true);
      try {
        // try fetching address from Amwell first
        if (sdk && consumer) {
          const shippingAddress = await sdk?.consumerService.getShippingAddress(consumer);
          const initialAddressValues: PharmacyAddressConfirmationProps = {
            address: {
              line1: shippingAddress.address1 || "",
              line2: shippingAddress.address2 || "",
              city: shippingAddress.city || "",
              state: shippingAddress.stateCode as StateCode,
              postalCode: shippingAddress.zipCode || "",
            },
          };
          setFormValues(initialAddressValues);
          setLoading(false);
        }
      } catch (e: any) {
        // otherwise, fetch patient address
        if (e["__errorCode"] === "noShippingAddressFound") {
          const { data } = await getPatientData();
          const patientDemographics = data?.patient.demographics;
          if (patientDemographics) {
            const initialAddressValues: PharmacyAddressConfirmationProps = {
              address: {
                line1: patientDemographics.address1 || "",
                line2: patientDemographics.address2 || "",
                city: patientDemographics.city || "",
                state: patientDemographics.state as StateCode,
                postalCode: patientDemographics.zipCode || "",
              },
            };
            setFormValues(initialAddressValues);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      }
    };
    initForm();
  }, []);

  const submitForm = async (
    values: PharmacyAddressConfirmationProps,
    actions: FormikHelpers<PharmacyAddressConfirmationProps>,
  ) => {
    try {
      if (sdk && consumer) {
        const countries = await sdk.getCountries();
        const usa = countries.find(({ code }) => code === US_COUNTRY_CODE);
        const awsdkState = usa?.states.find(({ code }) => code === StateCode[values.address.state]);
        if (awsdkState) {
          const address = sdk.consumerService.newAddressUpdate(
            values.address.line1,
            values.address.line2 || "",
            values.address.city,
            awsdkState,
            values.address.postalCode,
          );
          await sdk.consumerService.updateShippingAddress(consumer, address);
          handleSubmit({ address: values.address });
        } else {
          showToast({
            message: t("unknownError", { ns: "form-telehealth-previsit" }),
            icon: "alert",
            type: "error",
          });
        }
      }
    } catch (e: any) {
      handleError(e, actions, values);
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={formValues}
      validationSchema={addressValidationSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting, isValid }) => (
        <FormContent css={layout.margin("gutter", "skip")}>
          <Title>
            {t("pharmacyAddressConfirmation.title", { ns: "form-telehealth-previsit" })}
          </Title>
          {loading ? (
            <Loading />
          ) : (
            <AddressForm
              ctaTitle={t("continue", { ns: "form-telehealth-previsit" })}
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          )}
        </FormContent>
      )}
    </Formik>
  );
};

export default PharmacyAddressConfirmation;
