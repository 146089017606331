import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import { AlertCircle } from "react-feather";
import { Helmet } from "react-helmet-async";
import { Trans } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { auth, scheduling } from "/apollo/client/local";
import { useAppointmentsIndex } from "/b2c/query/appointments.b2c";
import InlineMessage from "/component/base/InlineMessage";
import Link from "/component/base/Link";
import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import AddressModal from "/component/modal/AddressModal";
import AthenaBillingLearnMoreModal from "/component/modal/AthenaBillingLearnMoreModal";
import OnboardingModal from "/component/modal/OnboardingModal";
import SetAddress from "/component/partial/SetAddress";
import routes from "/constant/url.constant";
import { useCedarPendingInvoiceCount, useOutages, useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { AnalyticsSource, AnalyticsUserFlow, ScreenViewParams } from "/util/firebase.util";

import { BaseLink, Divider, HeaderContainer } from "./Dashboard.styles";
import DashboardLinks from "./DashboardLinks";
import UpcomingVisits from "./UpcomingVisits";
import useDashboard from "./useDashboard";

const HomePage = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation("home");
  const { data, loading } = useDashboard();
  const { data: appointmentsData, loading: appointmentsLoading } = useAppointmentsIndex({
    showPast: false,
  });
  const { isAuthenticated } = useReactiveVar(auth.var);
  const { pendingInvoiceCount } = useCedarPendingInvoiceCount();
  const outage = useOutages("Dashboard");
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [learnMoreModalOpen, setLearnMoreModalOpen] = useState(false);
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

  useEffect(() => {
    scheduling.updateAnalytics(AnalyticsUserFlow.SCHEDULE_VISIT);
  }, []);

  const hasCmdFacilities = !!data?.cmdFacilities?.length;
  const hasSmgFacilities = !!data?.smgFacilities?.length;

  const patient = appointmentsData?.patient;

  return (
    <MainContent
      pageTitle={t("headline")}
      headerAccessory={
        <HeaderContainer>
          <SetAddress
            buttonStyle="blank"
            analyticsParams={
              {
                user_flow: AnalyticsUserFlow.HOME,
                source: AnalyticsSource.HOME,
              } as ScreenViewParams
            }
          />
        </HeaderContainer>
      }
    >
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      {outage && (
        <InlineMessage
          css={layout.margin("skip", "skip", "expanded", "skip")}
          icon={<AlertCircle />}
          variant="primary"
        >
          <Text variant="body2">
            {outage.message}
            {!!outage.link && (
              <BaseLink target="_blank" href={outage.link}>
                Learn More
              </BaseLink>
            )}
          </Text>
        </InlineMessage>
      )}

      {!!pendingInvoiceCount && (
        <InlineMessage
          css={layout.margin("skip", "skip", "expanded", "skip")}
          icon={<AlertCircle />}
          variant="important"
        >
          <Text>
            <Trans
              components={{ invoicesLink: <Link to={"account/billing-accounts"} /> }}
              i18nKey="invoiceReady"
              t={t}
              values={{ count: pendingInvoiceCount }}
            />
          </Text>
        </InlineMessage>
      )}

      <DashboardLinks
        onLinkClick={(link) => {
          if (link === routes.telehealthIntro && !isAuthenticated) {
            setOnboardingModalOpen(true);
          } else {
            history.push(link);
          }
        }}
        hasCmdFacilities={hasCmdFacilities}
        hasSmgFacilities={hasSmgFacilities}
        isLoading={loading}
      />
      <Divider css={layout.margin(40, "skip")} />
      <UpcomingVisits data={patient} isLoading={appointmentsLoading && !patient} />
      <AddressModal
        initialValue={""}
        isOpen={addressModalOpen}
        close={() => setAddressModalOpen(false)}
      />
      <AthenaBillingLearnMoreModal
        isOpen={learnMoreModalOpen}
        close={() => setLearnMoreModalOpen(false)}
      />
      {onboardingModalOpen && <OnboardingModal onClose={() => setOnboardingModalOpen(false)} />}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, HomePage, undefined, "ai-tracking");
