import { gql, useQuery } from "@apollo/client";

import { FindCreateNotificationPreferences } from "/apollo/schema";

export const NotificationPreferenceOptionFragment = gql`
  fragment NotificationPreferenceOptionFragment on NotificationPreferenceOption {
    emailNotification
    pushNotification
  }
`;

const FIND_CREATE_NOTIFICATION_PREFERENCES = gql`
  query FindCreateNotificationPreferences {
    viewer {
      user {
        notificationPreference {
          appointmentReminders {
            ...NotificationPreferenceOptionFragment
          }
          patientInformation {
            ...NotificationPreferenceOptionFragment
          }
        }
      }
    }
  }

  ${NotificationPreferenceOptionFragment}
`;

export const useNotifications = () =>
  useQuery<FindCreateNotificationPreferences>(FIND_CREATE_NOTIFICATION_PREFERENCES, {
    fetchPolicy: "network-only",
  });
