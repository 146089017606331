import { useReactiveVar } from "@apollo/client";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { auth } from "/apollo/client/local";
import AmwellPrevisitProvider from "/component/provider/AmwellPrevisitProvider";
import AppRoute from "/component/root/AppRoot/component/AppRoute";
import routes from "/constant/url.constant";
import { useAmwell } from "/hook";

import TelehealthIntro from "./page/TelehealthIntro";
import TelehealthLocationVerification from "./page/TelehealthLocationVerification";
import TelehealthPrevisit from "./page/TelehealthPrevisit";
import TelehealthWaitingRoom from "./page/TelehealthWaitingRoom";
import TelehealthIndex from "./TelehealthIndex";

const TelehealthRoutes = ({ match, history }: RouteComponentProps) => {
  const { isReady, consumer } = useAmwell();
  const { isAuthenticated } = useReactiveVar(auth.var);

  return (
    <AmwellPrevisitProvider
      handleError={() => {
        history.push(routes.telehealth);
      }}
    >
      <Switch>
        <AppRoute exact path={`${match.url}/intro`} component={TelehealthIntro} />
        <AppRoute exact path={`${match.url}/providers`} component={TelehealthIndex} />
        {!isAuthenticated && isReady && !consumer && <Redirect to={routes.root} />}
        <AppRoute
          exact
          path={`${match.url}/locationVerification`}
          component={TelehealthLocationVerification}
        />
        <AppRoute exact path={`${match.url}/previsit`} component={TelehealthPrevisit} />
        <Route exact path={routes.telehealthWaitingRoom} component={TelehealthWaitingRoom} />
      </Switch>
    </AmwellPrevisitProvider>
  );
};

export default TelehealthRoutes;
