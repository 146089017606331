import styled from "styled-components";

import { Button, Text } from "/component/base";

export const Base = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `1fr min(80ch, calc(100% - ${theme.spacing.expanded}px)) 1fr`,
  gridColumnGap: theme.spacing.standard,
  height: "100%",
}));

export const Content = styled.div`
  place-self: center;
  grid-column: 2;
  margin-top: clamp(-50%, -10vh, -15%);
`;

export const Header = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: theme.spacing.standard,
}));

export const Body = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing.standard,
}));

export const Title = styled(Text).attrs({
  variant: "title3",
})(({ theme }) => ({
  marginBottom: theme.spacing[20],
}));

export const CTAButton = styled(Button).attrs({
  variant: "secondary",
})(({ theme }) => ({
  width: "max-content",
  paddingInline: theme.spacing[40],
  margin: `${theme.spacing[40]}px auto 0 auto`,
}));
