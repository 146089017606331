import React from "react";
import { Trans } from "react-i18next";

import { Text } from "/component/base";
import i18n from "/i18n";

export const notificationCategories = {
  appointments: "appointmentReminders",
  patientInfo: "patientInformation",
} as const;

interface NotificationOptions {
  email?: boolean | null;
  emailNotification?: boolean | null;
  paperMail?: boolean | null;
  phoneCall?: boolean | null;
  phoneText?: boolean | null;
  ringlessCall?: boolean | null;
  smsNotification?: boolean | null;
  appointmentReminders?: boolean | null;
  prescriptions?: boolean | null;
  patientInformation?: boolean | null;
  pushNotification?: boolean | null;
  dealsAndPromotions?: boolean | null;
}

type NotificationKey =
  | "email"
  | "emailNotification"
  | "paperMail"
  | "phoneCall"
  | "phoneText"
  | "ringlessCall"
  | "smsNotification"
  | "appointmentReminders"
  | "patientInformation";

type SettingsState = { label: string; type: NotificationKey; value: boolean };
export type StoreObject = { [key: string]: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
type GetFormattedOptions = (options: NotificationOptions | null) => SettingsState[];
export type NotificationCategory =
  | typeof notificationCategories.appointments
  | typeof notificationCategories.patientInfo;
/**
 * Given the name of a NotificationPreferenceOption, return the appropriate text
 * content (ie, title and disclaimer, if any).
 */
export const getTextContentForCategory = (category: NotificationCategory) => {
  switch (category) {
    case notificationCategories.appointments:
      return {
        disclaimer: (
          <Trans
            components={{ bold: <Text color="inherit" variant="body2Bold" /> }}
            i18n={i18n}
            i18nKey="disclaimer.notificationsOn"
            ns="notifications"
            values={{
              category: i18n.t("disclaimer.appointments", { ns: "notifications" }),
              type: i18n.t("disclaimer.emailSms", { ns: "notifications" }),
            }}
          />
        ),
        title: i18n.t("title.appointments", { ns: "notifications" }),
      };
    case notificationCategories.patientInfo:
      return {
        disclaimer: (
          <Trans
            components={{ bold: <Text color="inherit" variant="body2Bold" /> }}
            i18nKey="disclaimer.notificationsOn"
            i18n={i18n}
            ns="notifications"
            values={{
              category: i18n.t("disclaimer.patientInfo", { ns: "notifications" }),
              type: i18n.t("disclaimer.email", { ns: "notifications" }),
            }}
          />
        ),
        title: i18n.t("title.patientInfo", { ns: "notifications" }),
      };
    default:
      return { title: i18n.t("title.notifications", { ns: "notifications" }) };
  }
};

/**
 * getFormattedOptions - given either a billingAccount's settings, or a
 * Notification Preference Option, this fn returns an array options that may be
 * rendered as toggles in the UI, including a label, the current value, and the
 * type of the toggle (ie, which specific setting it represents).
 */
export const getFormattedOptions: GetFormattedOptions = (options) =>
  options
    ? (Object.keys(options)
        .map((key) => {
          switch (key) {
            case "emailNotification":
            case "email":
              return {
                label: i18n.t("label.email", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            // case "smsNotification":
            case "phoneText":
              return {
                label: i18n.t("label.sms", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "phoneCall":
              return {
                label: i18n.t("label.phoneCall", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "appointmentReminders":
              return {
                label: i18n.t("label.appointmentReminders", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "pushNotification":
              return {
                label: i18n.t("label.pushNotification", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "patientInformation":
              return {
                label: i18n.t("label.patientInformation", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            default:
              return undefined;
          }
        })
        .filter((option) => !!option) as SettingsState[])
    : [];
