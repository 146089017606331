import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";

import { address } from "/apollo/client/local";
import { FacilityOrg, GetFacilities_facilities } from "/apollo/schema";
import { InlineMessage } from "/component/base";
import { Info } from "/component/base/Icon";
import Link from "/component/base/Link";
import List from "/component/base/List";
import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import OnboardingModal from "/component/modal/OnboardingModal";
import SetAddress from "/component/partial/SetAddress";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import getDistance from "/util/distance.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  IndentifierParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import { HeaderContainer, UrgentCareNearbyContainer } from "./UrgentCareIndex.styles";
import UrgentCareItem from "./UrgentCareItem";
import { useUrgentCareFacilities } from "./useUrgentCareFacilities";

const UrgentCareIndex = () => {
  const { t } = useTranslation("urgentCare");
  const history = useHistory();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const addressStored = useReactiveVar(address.var);
  const { data, refetch } = useUrgentCareFacilities(FacilityOrg.CMD);

  const getMiles = (facility: GetFacilities_facilities) => {
    const showMiles = addressStored.lat && addressStored.lng;
    return showMiles && facility.coordinates
      ? getDistance(
          {
            lat: addressStored.lat as number,
            lng: addressStored.lng as number,
          },
          {
            lat: facility.coordinates.lat,
            lng: facility.coordinates.lng,
          },
        )
      : undefined;
  };

  // Refetch data if the address variable is updated
  useEffect(() => {
    refetch();
  }, [addressStored]);

  const logButtonClickEvent = (buttonName: string, params?: IndentifierParams) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.FIND_CITYMD,
      source: AnalyticsSource.URGENT_CARE_FACILITIES,
      button_name: buttonName,
      ...params,
    } as ButtonClickParams);
  };

  return (
    <MainContent parentPage="root" pageTitle="">
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      <HeaderContainer>
        <SetAddress
          header={t("subHeader")}
          variant="unstyled"
          textVariant="title2"
          buttonStyle="unstyled"
          textColor="textPrimary"
          analyticsParams={
            {
              user_flow: AnalyticsUserFlow.FIND_CITYMD,
              source: AnalyticsSource.URGENT_CARE_FACILITIES,
            } as ScreenViewParams
          }
        />
      </HeaderContainer>

      <InlineMessage
        css={layout.margin("expanded", "skip", "standard", "skip")}
        icon={<Info size={24} color="brandSecondary" />}
        variant="important"
      >
        <Text variant="body2">{t("infoMessage")}</Text>
      </InlineMessage>
      <div css={layout.margin("skip", "skip", "standard", "skip")}>
        <Text variant="body2">{t("disclaimer")}</Text>
      </div>

      {data?.facilities && data?.facilities.length > 0 && (
        <List
          as="Buttons"
          data={data.facilities}
          showChevron={false}
          renderItem={(facility) => (
            <UrgentCareItem miles={getMiles(facility)} facility={facility} />
          )}
          onClick={(facility) => {
            logButtonClickEvent("Facility", { facility_id: facility.id.toString() });
            history.push(`${routes.urgentCare}/${facility.id}`);
          }}
        />
      )}

      <UrgentCareNearbyContainer>
        <Text variant="title3">{t("noUrgent.title")}</Text>
        <Text variant="body1" color="textSecondary">
          {t("noUrgent.message")}
        </Text>
      </UrgentCareNearbyContainer>
      <Link.Button to="/telehealth" variant="tertiary">
        {t("virtualCareAvailability")}
      </Link.Button>
      {showOnboarding && (
        <OnboardingModal
          redirectPage={window.location.href}
          onClose={() => setShowOnboarding(false)}
        />
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, UrgentCareIndex, undefined, "ai-tracking");
