import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import noMessages from "/asset/image/secureMessagingNoMessages.png";
import { List, Loading, Text } from "/component/base";
import MainContent from "/component/layout/MainContent";
import { useUserAccount } from "/component/layout/MainLayout/useUserAccount";
import EmailVerificationModal from "/component/modal/EmailVerificationModal/EmailVerificationModal";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import routes from "/constant/url.constant";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { SIGN_UP_V2_ENABLED } from "/util/auth.util";

import { ListContainer } from "./Notifications.styles";
import { PreferenceGroup, preferenceKeys } from "./Notifications.types";
import { useNotifications } from "./useNotifications";

const IMAGE_SIZE = 274;

const Notifications = () => {
  const { t } = useTranslation("notifications");
  const { data, error, loading } = useNotifications();
  const history = useHistory();
  const translations = {
    [preferenceKeys.appointmentReminders]: {
      title: t("title.appointments"),
      description: t("description.appointmentReminders"),
    },
    [preferenceKeys.patientInformation]: {
      title: t("title.patientInfo"),
      description: t("description.patientInformation"),
    },
  };

  const { data: userData, loading: userDataLoading } = useUserAccount();
  const [accountQueried, setAccountQueried] = React.useState(false);
  const [showEmailModal, setShowEmailModal] = React.useState(false);
  const emailVerification = userData?.viewer?.user?.account?.emailVerificationStatus;
  const firstName = userData?.viewer?.user.account.firstName || "";
  const lastName = userData?.viewer?.user.account.lastName || "";
  const patientName = `${firstName} ${lastName}`;
  const userEmail = userData?.viewer?.user.account.email || "";

  const preferenceGroups = Object.keys(data?.viewer?.user?.notificationPreference || [])
    .map((key) =>
      preferenceKeys[key]
        ? {
            subtitle: translations[key].description,
            title: translations[key].title,
            to: `/account/notifications/${key}`,
          }
        : undefined,
    )
    .filter((group) => !!group) as PreferenceGroup[];
  const showEmptyState = error || preferenceGroups.length < 1;

  useEffect(() => {
    if (!userDataLoading && !accountQueried) {
      setAccountQueried(true);
      if (
        SIGN_UP_V2_ENABLED &&
        emailVerification &&
        ["notVerified", "pending"].indexOf(emailVerification) >= 0
      ) {
        setShowEmailModal(true);
      }
    }
  }, [userDataLoading, accountQueried, emailVerification]);

  return (
    <MainContent pageTitle={t("title.notifications")} parentPage="account">
      {loading && showEmptyState && <Loading variant="page" />}

      {!loading && showEmptyState && (
        <EmptyStateCta
          imageHeight={IMAGE_SIZE}
          imageWidth={IMAGE_SIZE}
          imageSource={noMessages}
          subtitle={t("error.subtitle")}
          title={t("error.title")}
        />
      )}

      {!showEmptyState && (
        <ListContainer>
          <List
            as="Links"
            data={preferenceGroups}
            hideLastChildBorder
            renderItem={({ subtitle, title }) => (
              <div css={[layout.flexVertical, layout.padding("standard", "skip")]}>
                <Text variant="body1Bold">{title}</Text>

                <Text color="textSecondary" variant="body2">
                  {subtitle}
                </Text>
              </div>
            )}
          />
        </ListContainer>
      )}
      <EmailVerificationModal
        isOpen={showEmailModal}
        email={userEmail}
        patientName={patientName}
        close={() => {
          setShowEmailModal(false);
          history.replace(routes.root);
        }}
      />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Notifications, undefined, "ai-tracking");
