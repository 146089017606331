import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import useDemographics from "/b2c/query/useDemographics.b2c";
import { Button, Link, Loading, Text } from "/component/base";
import * as Icons from "/component/base/Icon";
import { useUserAccount } from "/component/layout/MainLayout/useUserAccount";
import routes from "/constant/url.constant";
import { useAppInit } from "/hook";

import { Base, Body, Content, CTAButton, Header, Title } from "./AccountError.styles";
const AccountErrorPage = () => {
  const { logout } = useAppInit();
  const { data: accountData, loading: loadingUser } = useUserAccount();
  const history = useHistory();
  const { data: demographicsData, loading: loadingDemographics, error } = useDemographics();

  const [firstError] = error?.graphQLErrors ?? [];
  const { extensions } = firstError ?? {};
  const statusCode = extensions?.status_code;
  // This page is only shown to users with error 404 or 422 in the demographics query
  if (demographicsData && (statusCode !== 404 || statusCode !== 422)) {
    history.push(routes.root);
  }

  const { t } = useTranslation("layout-AccountError");
  const patientEmail = accountData?.viewer?.user.account.email;
  const SUPPORT_EMAIL = "appsupport@summithealth.com";

  const openAthenaPortal = () => {
    const url = "https://2983-1.portal.athenahealth.com/";
    window.open(url, "_blank");
  };

  if (loadingUser || loadingDemographics) {
    return <Loading />;
  }
  return (
    <Base>
      <Content>
        <Button
          onClick={() => {
            logout();
            history.push(routes.root);
          }}
          variant="blank"
        >
          <Icons.ArrowLeft color="textRed" />
        </Button>
        <Header>
          <Icons.Info size={40} color="brandPrimary" />
          <Title>{t("accountError.title")}</Title>
        </Header>
        <Body>
          <Text>
            <Trans
              i18nKey="accountError.paragraphOne"
              t={t}
              components={{
                anchor: (
                  <Link.Anchor
                    href={encodeURI(`mailto:${SUPPORT_EMAIL}
          ?subject=${t("accountError.email.subject")}
          &body=${t("accountError.email.body", { email: patientEmail })}`)}
                    variant="tertiary"
                  />
                ),
              }}
              values={{ email: SUPPORT_EMAIL }}
            />
          </Text>
          <Text>{t("accountError.paragraphTwo")}</Text>
          <CTAButton onClick={openAthenaPortal}>{t("accountError.loginToAthena")}</CTAButton>
        </Body>
      </Content>
    </Base>
  );
};

export default AccountErrorPage;
