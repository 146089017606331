import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { isAfter, isBefore } from "date-fns";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { address } from "/apollo/client/local";
import { GetFacility_facility } from "/apollo/schema";
import Live from "/asset/svg/live.svg";
import { Divider, InlineMessage, Loading, Text } from "/component/base";
import { AlertCircle } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import HoursOfOperation from "/component/partial/HoursOfOperation";
import { useTranslation } from "/hook";
import useWindowSize from "/hook/useWindowSize";
import { layout } from "/styles";
import { screenSizes } from "/theme/default/breakpoints";
import { links } from "/util";
import { reactPlugin } from "/util/appInsights.util";
import { parseDateTime } from "/util/date";
import getDistance from "/util/distance.util";
import { getEstimatedWaitTime } from "/util/facility.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import {
  AddressContainer,
  ButtonsContainer,
  ContentContainer,
  DetailsContainer,
  LiveImage,
  MapStyled,
  SectionHeader,
  ServiceText,
  UrgentCareButton,
  VerticalContainer,
} from "./UrgentCareFacility.styles";
import { useQueryUrgentCareFacility } from "./useQueryUrgentCareFacility";

const UrgentCareFacility = () => {
  const { t } = useTranslation("page-UrgentCare-page-UrgentCareFacility");
  const storedAddress = useReactiveVar(address.var);
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth && windowWidth <= screenSizes.tabletPortrait;
  const { facilityId } = useParams<{ facilityId: string }>();
  const { data, error, loading } = useQueryUrgentCareFacility(facilityId || "");
  const center = `${data?.facility.coordinates?.lat},${data?.facility.coordinates?.lng}`;
  const phoneNumber = data?.facility.contactInfo?.phoneNumber || "";
  const today = new Date();
  const hoursToday =
    data?.facility.hoursOfOperation && data?.facility.hoursOfOperation[today.getDay()];

  let isOpen = false;
  if (hoursToday && hoursToday.openingTime && hoursToday.closingTime) {
    const openingDateTime = parseDateTime(hoursToday.openingTime);
    const closingDateTime = parseDateTime(hoursToday.closingTime);

    if (isAfter(today, openingDateTime) && isBefore(today, closingDateTime)) {
      isOpen = true;
    }
  }

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.FIND_CITYMD,
      source: AnalyticsSource.URGENT_CARE_FACILITY_DETAILS,
      button_name: buttonName,
      facility_id: facilityId,
    } as ButtonClickParams);
  };

  // Calculate mile distances between address and destination
  const getMiles = (facility: GetFacility_facility) => {
    const showMiles = storedAddress.lat && storedAddress.lng;
    return showMiles && facility.coordinates
      ? getDistance(
          {
            lat: storedAddress.lat as number,
            lng: storedAddress.lng as number,
          },
          {
            lat: facility.coordinates.lat,
            lng: facility.coordinates.lng,
          },
        )
      : undefined;
  };

  const title = data
    ? t("title", { facilityName: data?.facility.facilityNameShort })
    : t("titleLoading");

  const getChild = () => {
    if (loading) {
      return <Loading variant="page" />;
    }

    if (error) {
      return (
        <InlineMessage icon={<AlertCircle />} variant="important">
          <Text variant="body2">{error.message}</Text>
        </InlineMessage>
      );
    }

    return (
      <>
        {isMobile && <MapStyled alt={t("directions")} height={258} center={center} />}
        <div css={[layout.flexVerticalAlignStart]}>
          <Text element="h1" variant="title1">
            {data?.facility.facilityNameLong}
          </Text>

          <AddressContainer>
            <Text variant="body1">
              {[
                data?.facility.address?.line1?.replace(/\.$/, ""),
                data?.facility.address?.city,
                data?.facility.address?.state,
                data?.facility.address?.postalCode,
              ]
                .filter(Boolean)
                .join(", ")}
              {data?.facility && (
                <Text color="textSecondary" variant="body1">
                  {t("miAway", { miles: getMiles(data?.facility) })}
                </Text>
              )}
            </Text>
          </AddressContainer>

          {phoneNumber && (
            <Text
              css={layout.margin(2, "skip", "skip", "skip")}
              color="brandPrimary"
              variant="body1Bold"
              onClick={() => {
                window.location.href = `tel:+1${phoneNumber.replace(/[-() ]/g, "")}`;
              }}
            >
              {phoneNumber}
            </Text>
          )}
        </div>
        <InlineMessage css={layout.margin("standard", "skip", "gutter", "skip")}>
          <Text>{t("disclaimerEstimatedTime")}</Text>
        </InlineMessage>

        {isOpen && !!data?.facility?.waitTimeSeconds && (
          <div css={layout.flexCenterHorizontal}>
            <Text variant="body1">
              {t("estimatedWait")}
              <Text variant="body1Bold">{getEstimatedWaitTime(data.facility.waitTimeSeconds)}</Text>
            </Text>
            <LiveImage src={Live} alt="" />
          </div>
        )}

        <ButtonsContainer>
          <UrgentCareButton
            href={links.getGoogleMapsLink(data?.facility)}
            target="_blank"
            variant="secondary"
            onClick={() => {
              logButtonClickEvent("Get directions in maps");
            }}
          >
            <Text variant="title3" color="brandPrimary">
              {t("directions")}
            </Text>
          </UrgentCareButton>
        </ButtonsContainer>

        {!isMobile && <MapStyled alt={t("directions")} height={258} center={center} />}

        <ContentContainer>
          <DetailsContainer>
            {data?.facility.hoursOfOperation && (
              <VerticalContainer>
                <SectionHeader>{t("hours")}</SectionHeader>
                <HoursOfOperation hoursOfOperation={data?.facility.hoursOfOperation} />
              </VerticalContainer>
            )}
            <Divider css={layout.margin(40, "skip")} />
            {data?.facility.supportedServices && (
              <VerticalContainer>
                <SectionHeader>{t("services")}</SectionHeader>
                <ul>
                  {data.facility.supportedServices.map((service, idx) => (
                    <ServiceText key={idx}>{service}</ServiceText>
                  ))}
                </ul>
              </VerticalContainer>
            )}
          </DetailsContainer>
        </ContentContainer>
      </>
    );
  };

  return (
    <MainContent parentPage="urgentCare">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {getChild()}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, UrgentCareFacility, undefined, "ai-tracking");
