/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getAnalytics,
  logEvent as logEventFB,
  setAnalyticsCollectionEnabled,
  setUserId as setUserIdFB,
  setUserProperties as setUserPropertiesFB,
} from "firebase/analytics";
import { initializeApp } from "firebase/app";

import { ScmdOrganization } from "/apollo/schema";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

const analytics = getAnalytics();

let environment = "dev";
if (process.env.API_URL === "http://localhost:3000") {
  setAnalyticsCollectionEnabled(analytics, false);
} else if (process.env.API_URL === "https://as-cl1-dcm-api-prod.azurewebsites.net") {
  environment = "prod";
}

setUserPropertiesFB(analytics, { environment, authenticated: "false" });

export enum AnalyticsEvent {
  // fields
  BUTTON_CLICK = "button_click",
  CHECKBOX_CLICK = "checkbox_click",
  DROPDOWN_SELECT = "dropdown_select",
  FIELD_COMPLETE = "field_complete",
  OPTION_SELECT = "option_select",

  // misc
  ERROR_VIEW = "error_view",
  FILE_DOWNLOAD = "file_download",
  FILTER = "filter",
  PAYMENT_COMPLETE = "payment_complete",
  // screenView being camelCase is an exception since Firebase
  // already had a screen_view event and we needed a custom one
  SCREEN_VIEW = "screenView",
  PRE_REGISTER = "pre_register",

  // telehealth
  AMWELL_VISIT_START = "amwell_visit_start",
  AMWELL_VISIT_FFA_START = "amwell_visit_ffa_start",
  AMWELL_VISIT_END = "amwell_visit_end",
  AMWELL_VISIT_REJOIN = "amwell_visit_rejoin",
  AMWELL_CHAT_OPEN = "amwell_chat_open",
  AMWELL_CHAT_SEND = "amwell_chat_send",
  ATHENA_VISIT_START = "athena_visit_start",

  // modals
  APP_MODAL_VIEW = "app_modal_view",
  APP_MODAL_DISMISS = "app_modal_dismiss",
  SYSTEM_MODAL_VIEW = "system_modal_view",
  SYSTEM_MODAL_DISMISS = "system_modal_dismiss",

  PROVIDER_SEARCH_NOT_FOUND = "provider_search_no_provider_found",
}

export enum AnalyticsUserFlow {
  APPOINTMENTS = "appointments", // named 'All visits' in spreadsheet
  APPOINTMENT_DETAILS = "appointment_detail",
  APP_UPDATE = "app_update",
  APP_REVIEW = "app_review",
  BILLS_AND_PAYMENTS = "bills_and_payments",
  FIND_CITYMD = "find_citymd",
  GET_VIRTUAL_URGENT_CARE = "get_virtual_urgent_care",
  HOME = "home", // dashboard
  LOCATION = "location",
  MESSAGES = "messages",
  ONBOARDING_NEW = "onboarding_new",
  ONBOARDING_OLD = "onboarding_old",
  MY_ACCOUNT = "my_account", // account settings
  MY_HEALTH = "my_health",
  RESULTS = "results",
  SCHEDULE_VISIT = "schedule_visit",
  PROVIDER_SEARCH = "provider_search",
}

// same as screen names
export enum AnalyticsSource {
  // Onboarding (new)
  ONBOARDING_ACCOUNT_CREATED = "OnboardingAccountCreated",
  ONBOARDING_EMAIL_VERIFICATION = "OnboardingEmailVerification",
  ONBOARDING_SIGN_IN = "OnboardingSignIn",
  ONBOARDING_SIGN_UP = "OnboardingSignUp",
  ONBOARDING_SMS_VERIFICATION = "OnboardingSMSVerification",
  ONBOARDING_STATUS = "OnboardingStatus",

  // Onboarding (old)
  ONBOARDING_HOME = "OnboardingHome",
  ONBOARDING_DEMOGRAPHIC_INFO = "OnboardingDemographicInfo",
  ONBOARDING_LOCATION = "OnboardingLocation",

  // Home
  HOME = "Dashboard",
  // Home - Schedule a visit
  DECISION_FLOW = "DecisionFlow",
  DECISION_SUPPORT_FORM = "DecisionSupportForm",
  PROVIDER_AVAILABILITY_SEARCH = "ProviderAvailabilitySearch",
  PROVIDER_AVAILABILITIES = "ProviderAvailabilities",
  PROVIDER_FILTER = "ProviderFilter",
  VISIT_REASON = "VisitReason",
  BOOKING_CONFIRMED = "BookingConfirmed",
  CONFIRM_DETAILS = "ConfirmDetails",
  DECISION_SUPPORT_KICKOUT = "DecisionSupportKickout",
  // Home - Get virtual urgent care
  TELEHEALTH_PATIENT_SELECTION = "TelehealthPatientSelection",
  TELEHEALTH_PROVIDERS = "TelehealthProviders",
  TELEHEALTH_PROVIDER_DETAILS = "TelehealthProviderDetail",
  TELEHEALTH_FILTER = "TelehealthProviderFilter",
  // TELEHEALTH_PREVISIT_HEADER = "TelehealthPrevisitHeader", // not an actual screen name
  // Home - Find a CityMD
  URGENT_CARE_FACILITIES = "UrgentCareFacilities",
  URGENT_CARE_FACILITY_DETAILS = "UrgentCareFacility",
  // Home - Upcoming Visits
  APPOINTMENTS = "Appointments", // not an actual screen name
  APPOINTMENTS_UPCOMING = "AppointmentsUpcoming",
  APPOINTMENTS_PAST = "AppointmentsPast",
  APPOINTMENT_DETAILS = "Appointment",
  APPOINTMENT_FILTER = "AppointmentFilter",

  // My health
  MY_HEALTH = "MyHealthHome",
  // My health - Results
  RESULTS = "DiagnosticReports",
  RESULT_DETAILS = "TestResults",
  RESULT_FILTER = "DiagnosticReportFilter",
  // My health - Prescriptions
  PRESCRIPTIONS = "Prescriptions", // not an actual screen name
  PRESCRIPTIONS_ACTIVE = "PrescriptionsActive",
  PRESCRIPTION_PAST = "PrescriptionsPast",
  // My health - Preferred pharmacy
  PREFERRED_PHARMACY = "PharmacySettingsPreferred",
  PREFERRED_PHARMACY_DETAILS = "PharmacyDetailsModal",
  PREFERRED_PHARMACY_SEARCH = "PharmacySearch",
  // My health - Documents
  DOCUMENTS = "Documents",
  DOCUMENT_FILTER = "DocumentFilter",

  // My account
  MY_ACCOUNT = "ProfileHome",
  PERSONAL_INFO = "PersonalInfo",
  UPDATE_HOME_ADDRESS = "UpdateHomeAddress",
  CEDAR_BILLING_ACCOUNTS = "BillingAccounts",
  CEDAR_BILLING_ACCOUNT = "BillingAccount",
  CEDAR_INVOICE_DETAILS = "InvoiceDetails",
  CEDAR_PAYMENT_SHEET = "CedarPaymentSheet", // not an actual screen name
  CEDAR_CARD_ENTRY = "CardEntry",
  CEDAR_PAYMENT_PLAN_CUSTOMIZE = "Customize",
  CEDAR_PAYMENT_PLAN_DETAILS = "Details",
  CEDAR_PAYMENT_PLAN_SUMMARY = "Summary",
  INSURANCE = "InsuranceSettingsHome",
  MESSAGES = "Messages", // not an actual screen name
  MESSAGES_INBOX = "Inbox",
  MESSAGES_SENT = "Sent",
  ACCOUNT_SETTINGS = "AccountSettings",
  FORGOT_PASSWORD = "ConfirmForgotPassword",
  NOTIFICATIONS = "NotificationSettingsHome",
  FAQ_HOME = "FaqHome",
  FAQ_CATEGORY = "FaqCategory",
  FAQ_DETAIL = "FaqDetail",

  // Location
  LOCATION = "SetAddress",

  // Modals/Dialogs (not actual screen names)
  MODAL_APP_REVIEW = "AppReviewModal",
  MODAL_APP_UPDATE = "AppUpdateModal",
  MODAL_CANCEL_APPOINTMENT = "CancelAppointmentModal",
  MODAL_EMAIL_EXISTS = "EmailExistsModal",
  MODAL_EXIT_FLOW = "ExitFlowModal",
  MODAL_EXIT_BOOKING = "ExitBookingModal",
  MODAL_STOP_PAYMENT_PLAN = "StopPaymentPlanModal",

  PROVIDER_SEARCH_SELECTED = "ProviderSearchSelectedProvider",

  // Misc
  TAB_BAR = "TabBar", // not an actual screen name
}

interface BaseParams {
  user_flow: AnalyticsUserFlow;
  source?: AnalyticsSource | string;
}

export interface IndentifierParams {
  appointment_id?: string;
  availability_id?: string;
  calendar_id?: string;
  document_id?: string;
  facility_id?: string;
  message_id?: string;
  patient_id?: string;
  pharmacy_id?: string;
  provider_id?: string;
  result_id?: string;
}

// interfaces for params related to AnalyticsEvent
export interface ButtonClickParams extends BaseParams, IndentifierParams {
  button_name: string;
  button_description?: string;
}

export interface FieldCompleteParams extends BaseParams {
  field_name: string;
  field_content: string;
}

export interface OptionSelectParams extends BaseParams {
  option_question: string;
  option_selection: string;
}

export interface ErrorViewParams extends BaseParams {
  error_code?: string; // code from API, invalid_field
  error_message?: string;
  display_type: "toast" | "dialog" | "input_field";
}

export interface FileDownloadParams extends BaseParams {
  file_name: string;
  file_type: string;
}

export interface FilterParams extends BaseParams {
  start_date: string;
  end_date: string;
  types?: string;
  providers?: string;
  sort_by?: "ASC" | "DESC";
  radius?: string;
  days_of_week?: string;
  start_time?: string | null;
  end_time?: string | null;
  time_of_day?: string;
  gender?: string;
  languages?: string;
  filter_type: "appointment" | "document" | "provider" | "result" | "telehealthProvider";
}

export interface AppModalParams extends BaseParams, IndentifierParams {
  modal_name: AnalyticsSource;
  modal_title: string;
  modal_subtitle: string;
  modal_dismiss_reason?: string;
}

export interface SystemModalParams extends BaseParams {
  modal_name: AnalyticsSource;
  modal_title?: string;
  modal_subtitle?: string;
  modal_dismiss_reason?: string;
}

export interface ScreenViewParams extends BaseParams {
  screenName: string;
}

// mapping of web url route to mobile screen name
const reverseRouteMap = {
  // Internal Routes
  ["/appointments"]: AnalyticsSource.APPOINTMENTS, // not an actual screen name
  ["/appointments/:id"]: AnalyticsSource.APPOINTMENT_DETAILS,
  ["/home"]: AnalyticsSource.HOME,
  ["/my-health"]: AnalyticsSource.MY_HEALTH,
  ["/account"]: AnalyticsSource.MY_ACCOUNT,
  ["/scheduling"]: AnalyticsSource.DECISION_FLOW,
  ["/telehealth"]: AnalyticsSource.TELEHEALTH_PROVIDERS,
  ["/urgent-care"]: AnalyticsSource.URGENT_CARE_FACILITIES,
  ["/urgent-care/:id"]: AnalyticsSource.URGENT_CARE_FACILITY_DETAILS,
  // ["/signUp"]: AnalyticsSource.ONBOARDING_SIGN_UP, // TODO needs refactor to determine which screen in onboarding

  // My Health sub routes
  ["/my-health/results/:id"]: AnalyticsSource.RESULT_DETAILS,
  ["/my-health/results"]: AnalyticsSource.RESULTS,
  // ["/my-health/prescriptions/:id"]: "Prescription", // not used?
  ["/my-health/prescriptions"]: AnalyticsSource.PRESCRIPTIONS_ACTIVE,
  ["/my-health/pharmacy"]: AnalyticsSource.PREFERRED_PHARMACY,
  ["/my-health/pharmacy-search"]: AnalyticsSource.PREFERRED_PHARMACY_SEARCH,
  // ["/my-health/documents/:id"]: "Document", // not used?
  ["/my-health/documents"]: AnalyticsSource.DOCUMENTS,
  // ["/my-health/documents/visit"]: AnalyticsSource.DOCUMENT_DETAILS, // not used?

  // Account sub routes
  ["/account/billing-accounts"]: AnalyticsSource.CEDAR_BILLING_ACCOUNTS,
  [`/account/billing-accounts/${ScmdOrganization.CITY_MD}`]: AnalyticsSource.CEDAR_BILLING_ACCOUNT,
  [`/account/billing-accounts/${ScmdOrganization.SUMMIT_HEALTH}`]:
    AnalyticsSource.CEDAR_BILLING_ACCOUNT,
  [`/account/billing-accounts/${ScmdOrganization.CITY_MD}/invoices/:id`]:
    AnalyticsSource.CEDAR_INVOICE_DETAILS,
  [`/account/billing-accounts/${ScmdOrganization.SUMMIT_HEALTH}/invoices/:id`]:
    AnalyticsSource.CEDAR_INVOICE_DETAILS,
  ["/account/messages"]: AnalyticsSource.MESSAGES_INBOX,
  ["/account/messages/sent"]: AnalyticsSource.MESSAGES_SENT,
  ["/account/notifications"]: AnalyticsSource.NOTIFICATIONS,
  ["/account/settings"]: AnalyticsSource.ACCOUNT_SETTINGS,
  ["/account/insurance"]: AnalyticsSource.INSURANCE,
  ["/account/personal-information"]: AnalyticsSource.PERSONAL_INFO,

  // Scheduling sub routes
  ["/scheduling/availabilities"]: AnalyticsSource.PROVIDER_AVAILABILITY_SEARCH,
  ["/scheduling/availabilities/selected"]: AnalyticsSource.PROVIDER_AVAILABILITIES,
  ["/scheduling/decision-support/:id"]: AnalyticsSource.DECISION_SUPPORT_FORM,
  ["/scheduling/visit-reason"]: AnalyticsSource.VISIT_REASON,
  ["/scheduling/confirm"]: AnalyticsSource.CONFIRM_DETAILS,
  ["/scheduling/confirmed"]: AnalyticsSource.BOOKING_CONFIRMED,

  // Telehealth Sub routes
  // ["/telehealth/waitingRoom"]: "TelehealthWaitingRoom", // not used
  // Note: previsit mapping is done in telehealth.constant and screenView is in TelehealthPrevisit
  // ["/telehealth/previsit"]: "TelehealthPrevisit",
  ["/telehealth/locationVerification"]: "TelehealthLocationCatch",

  // ["/signup"]: "Demographics",
  // ["/contact-us"]: "ContactUs",
  ["/faq"]: AnalyticsSource.FAQ_HOME,
  ["/faq/category/:id"]: AnalyticsSource.FAQ_CATEGORY,
  ["/faq/detail/:id"]: AnalyticsSource.FAQ_DETAIL,
  // ["/passwordReset"]: "PasswordReset",
};

export const logEvent = (eventName: string, eventParams?: any) => {
  logEventFB(analytics, eventName, eventParams);
};

export const logScreenViewEvent = (locationPathname: string, locationSearch: string): void => {
  // use a regex to replace some special routes ending in "/:id" into a generic variable to match on
  const pathname = locationPathname
    .replace(/\/[a-z0-9]{32}/, "/:id") // invoice_id
    .replace(/\/\d+/, "/:id") // urgent_care_id, result_id, prescription_id, document_id, faq_category_id, or faq_detail_id
    .replace(/\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/, "/:id"); // decision support flow_id

  // default to screen name in route map
  let screenName = reverseRouteMap[pathname];

  // special cases
  if (locationSearch !== null) {
    if (locationPathname === "/appointments") {
      if (locationSearch === "?state=past") {
        screenName = AnalyticsSource.APPOINTMENTS_PAST;
      } else if (locationSearch === "?state=upcoming") {
        screenName = AnalyticsSource.APPOINTMENTS_UPCOMING;
      }
    } else if (locationPathname === "/my-health/prescriptions") {
      if (locationSearch === "?past=true") {
        screenName = AnalyticsSource.PRESCRIPTION_PAST;
      }
    }
  }

  // console.log("logScreenView:" + screenName);

  if (screenName) {
    logEvent(AnalyticsEvent.SCREEN_VIEW, {
      screenName: screenName,
    } as ScreenViewParams);
  }
};

export const setUserProperty = (key: string, value: string) => {
  setUserPropertiesFB(analytics, { [key]: value });
};

export const setUserId = (id: string) => {
  setUserIdFB(analytics, id);
};
