import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from "@apollo/client";

import {
  GetNotificationPreference,
  SetNotificationPreference,
  SetNotificationPreferenceVariables,
} from "/apollo/schema";

const GET_NOTIFICATION_PREFERENCE = gql`
  query GetNotificationPreference {
    viewer {
      user {
        notificationPreference {
          appointmentReminders {
            pushNotification
          }
          patientInformation {
            emailNotification
            pushNotification
          }
        }
      }
    }
  }
`;

const SET_NOTIFICATION_PREFERENCE = gql`
  mutation SetNotificationPreference($input: UpdateNotificationPreferenceInput!) {
    updateNotificationPreference(notificationData: $input) {
      errors {
        message
        type
      }
      notificationPreference {
        appointmentReminders {
          pushNotification
        }
        patientInformation {
          emailNotification
          pushNotification
        }
      }
    }
  }
`;

export const useNotificationPreference = (options?: QueryHookOptions<GetNotificationPreference>) =>
  useQuery<GetNotificationPreference>(GET_NOTIFICATION_PREFERENCE, options);

export const useSetNotificationPreference = (
  options?: MutationHookOptions<SetNotificationPreference, SetNotificationPreferenceVariables>,
) =>
  useMutation<SetNotificationPreference, SetNotificationPreferenceVariables>(
    SET_NOTIFICATION_PREFERENCE,
    options,
  );
