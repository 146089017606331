// Modules
import {
  ApolloClient as BaseApolloClient,
  createHttpLink,
  from,
  NormalizedCacheObject,
  Operation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { errorLink } from "/util/error";

import { auth, cache as localCache } from "./local";

const getSessionId = () => {
  const SESSION_STORAGE_KEY = "scmd_session";
  const SESSION_DURATION = 60 * 60 * 1000; // 1 hr
  const sessionStarted = parseInt(localStorage.getItem(SESSION_STORAGE_KEY) as string, 10);

  const now = Date.now();
  if (sessionStarted && now - sessionStarted < SESSION_DURATION) {
    return sessionStarted;
  }

  localStorage.setItem(SESSION_STORAGE_KEY, now.toString());
  return now;
};

const uriWithQueryParams = (operation: Operation): string => {
  const operationName = operation.operationName;

  const baseUri = `${process.env.API_URL}/graphql`;
  const session_id = getSessionId().toString();
  const clientName = "scmd-web";

  const paramsObj: { [key: string]: string } = { session_id, clientName, operationName };

  if (operationName === "createReservation") {
    const { providerId, providerAthenaId } = operation.getContext().provider || {};
    paramsObj.providerId = providerId;
    paramsObj.providerAthenaId = providerAthenaId;
  }

  const searchParams = new URLSearchParams(paramsObj);
  return `${baseUri}?${searchParams}`;
};

const httpLink = createHttpLink({
  uri: uriWithQueryParams,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${auth.read().token}`,
    },
  };
});

export const createClient = (): BaseApolloClient<NormalizedCacheObject> => {
  const client = new BaseApolloClient({
    cache: localCache,
    link: from([errorLink, authLink, httpLink]),
    connectToDevTools: process.env.NODE_ENV === "development",
  });

  return client;
};

const client = createClient();

export default client;
export type ApolloClient = typeof client;
