import React from "react";

import { useGenerateOnlineCheckInUrl } from "/b2c/mutation/useGenerateOnlineCheckInUrl.b2c";
import { useQueryAppointmentTelehealth } from "/b2c/query/useQueryAppointmentTelehealth.b2c";
import { AppointmentStatus, VisitType } from "/b2c/schema/types";
import DateBadge from "/component/base/DateBadge";
import { Clipboard, VideoFilled } from "/component/base/Icon";
import Text from "/component/base/Text";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { colors } from "/theme/default/colors";
import { date } from "/util";
import { showCompleteIntake } from "/util/appointment.util";
import {
  AnalyticsEvent,
  ButtonClickParams,
  IndentifierParams,
  logEvent,
} from "/util/firebase.util";

import { AppointmentButton, Base, Content, DetailsLink } from "./AppointmentCard.styles";
import { Props } from "./AppointmentCard.types";

const AppointmentCard = ({ appointment, className, analyticsParams }: Props) => {
  const { t } = useTranslation("partial-appointmentCard");
  const [getPrevisitSessionUrl, { loading: epionPrevisitLoading }] = useGenerateOnlineCheckInUrl({
    variables: {
      appointmentId: appointment.appointmentId,
      departmentId: appointment.departmentId,
    },
  });

  const [queryAppointmentTelehealth, { data: appointmentTelehealth }] =
    useQueryAppointmentTelehealth(appointment.appointmentId);

  const startDate = new Date(appointment.startDateTime);
  const endDate = new Date(appointment.endDateTime);
  const now = new Date();
  const isPastVisit = endDate.getTime() < now.getTime(); // compare unix epoch timestamp

  const isCancelled = AppointmentStatus.CANCELLED === appointment.appointmentStatus;
  const isNoShow =
    endDate.getTime() < now.getTime() &&
    [AppointmentStatus.FUTURE, AppointmentStatus.OPEN].includes(appointment.appointmentStatus);
  const isVirtual = appointment.visitType === VisitType.VIRTUAL;
  const canStartVirtualVisit =
    date.isDateToday(startDate) && isVirtual && !isPastVisit && !isCancelled;

  React.useEffect(() => {
    if (canStartVirtualVisit) {
      queryAppointmentTelehealth();
    }
  }, [canStartVirtualVisit, queryAppointmentTelehealth]);

  const logButtonClickEvent = (buttonName: string, params?: IndentifierParams) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      ...analyticsParams,
      button_name: buttonName,
      ...params,
    } as ButtonClickParams);
  };

  // Based on the state of the appointment we will render different information
  const getDetails = () => {
    if (isCancelled) {
      return (
        <Text color="textSecondary" variant="body2">
          {t("visitCanceled")}
        </Text>
      );
    } else if (isNoShow) {
      return (
        <Text color="textSecondary" variant="body2">
          {t("visitNoShow")}
        </Text>
      );
    }

    return (
      <>
        {appointment.provider?.specialty && (
          <Text color="textSecondary" variant="body2">
            {appointment.provider?.specialty}
          </Text>
        )}
        <Text variant="body2" color="textSecondary">
          {date.formatTimesWithDiff(startDate, new Date(appointment.endDateTime))}
        </Text>

        {!isVirtual && (
          <Text variant="body2" color="textSecondary">
            {appointment.department?.name}
          </Text>
        )}

        {!!isVirtual && (
          <div css={layout.flexCenterHorizontal}>
            <VideoFilled color="brandSecondary" size={16} />

            <Text
              variant="body2"
              color="statusNegative"
              css={layout.margin("skip", "skip", "skip", "base")}
            >
              {t("virtualVisit")}
            </Text>
          </div>
        )}
      </>
    );
  };

  const providerName = appointment.provider?.displayName || t("providerTBC");

  const intakeButton = (
    <AppointmentButton
      isLoading={epionPrevisitLoading}
      onClick={() => {
        logEvent(AnalyticsEvent.BUTTON_CLICK, {
          ...analyticsParams,
          button_name: "Complete intake form",
        } as ButtonClickParams);

        getPrevisitSessionUrl();
      }}
      variant="tertiary"
    >
      <Clipboard size={16} />
      <span css={layout.margin("skip", "skip", "skip", "condensed")}>{t("completeIntake")}</span>
    </AppointmentButton>
  );

  const startVisitButton = (
    <AppointmentButton
      isLoading={false}
      onClick={() => {
        logEvent(AnalyticsEvent.ATHENA_VISIT_START, {
          is_caregiver: "false",
        });
        const deepLink = appointmentTelehealth?.patient.appointmentTelehealthDeepLink?.deepLink;
        if (deepLink) {
          window.open(deepLink, "_blank");
        }
      }}
      variant="primary"
      size="small"
    >
      <VideoFilled size={16} />
      <span css={layout.margin("skip", "skip", "skip", "condensed")}>{t("startVisit")}</span>
    </AppointmentButton>
  );

  return (
    <Base
      aria-label={`${appointment.provider?.displayName} ${startDate.toLocaleDateString()}`}
      className={className}
    >
      <DetailsLink
        onClick={() =>
          logButtonClickEvent("Appointment", { appointment_id: String(appointment.appointmentId) })
        }
        to={{
          pathname: `${routes.appointments}/${appointment.appointmentId}`,
          state: appointmentTelehealth?.patient.appointmentTelehealthDeepLink,
        }}
        aria-label={t("ariaLabel.viewDetails")}
      >
        <DateBadge
          css={isCancelled || isNoShow ? { backgroundColor: colors.objectSubtle } : {}}
          date={startDate}
          dayTextColor={isCancelled || isNoShow ? "textSecondary" : "textTitleInverse"}
        />
        <Content>
          <Text color={isCancelled || isNoShow ? "textSecondary" : "textTitle"} variant="body1Bold">
            {providerName}
          </Text>
          {getDetails()}
        </Content>
      </DetailsLink>
      {!!showCompleteIntake(appointment) && intakeButton}
      {canStartVirtualVisit && appointmentTelehealth && startVisitButton}
    </Base>
  );
};

export default AppointmentCard;
